import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`On this Valentine’s Day, insights from payments data show that Americans will continue to increase their day of love spending on experiences, according to `}<a parentName="p" {...{
        "href": "https://newsroom.mastercard.com/news-briefs/mastercard-spendingpulse-love-for-experiences-is-in-the-air-this-valentines-day/"
      }}>{`an analysis`}</a>{` from ETA member Mastercard. According to Mastercard SpendingPulse, which provides overall retail spending trends across all payment types, including cash and check, experiential purchases – restaurants and hotels – is set experience solid growth this Valentine’s Day.`}</p>
    <p>{`For restaurants, retail sales could increase 5.4 percent to $15.3 billion today, Mastercard predicts. For hotels, sales could jump nearly ten percent year-over-year, reaching $1.4 billion.`}</p>
    <p>{`Unlikely to feel the love tonight are more traditional Valentine gifts, the report said. Jewelry sales are expected to decrease this year to $1.1 billion, despite a 2018 Valentine’s Day performance that surged over 25 percent higher than 2017. Overall luxury sales are likely to be flat, Mastercard predicts, generated $250 million and falling just .4 percent.`}</p>
    <p>{`Data from the National Retail Federation’s (NRF) `}<a parentName="p" {...{
        "href": "https://nrf.com/media-center/press-releases/fewer-consumers-celebrating-valentines-day-those-who-do-are-spending"
      }}>{`Valentine’s Day Spending Survey`}</a>{` predicts that consumers will spend $162 per person on average on Valentine’s Day in 2019, up from $144 in 2018. Despite the higher average spend, NRF data suggests a downward trend in participation in the holiday – 51 percent of Americans plan to celebrate the holiday in 2019, down from 55 percent last year and from a high of 63 percent in 2007.`}</p>
    <p>{`Significant others aren’t always the focus of Valentine’s Day spending, the NRF survey found. Gifts for pets continue to be popular, the NRF report says – 20 percent of consumers will buy a present for the pets, totaling $886 million and dwarfing 2008’s $519 million. And 11 percent of Americans plan on treating themselves to gifts like clothing and jewelry; just under 10 percent plan to get together with other single friends and family.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.electran.org/publication/transactiontrends/payments-analytics-highlight-valentines-day-spending-trends/"
      }}>{`https://www.electran.org/publication/transactiontrends/payments-analytics-highlight-valentines-day-spending-trends/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      